import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import Header from './sections/header/_header'
import AproveiteVantagens from './sections/aproveite-vantagens/_aproveite-vantagens'
import CartilhaCartaoConsignado from './sections/cartilha-cartao-consignado/_cartilha-cartao-consignado'
import CartaoConsignadoVideo from './sections/cartao-consignado-video/_cartao-consignado-video'
import CartaoInter from './sections/cartao-inter/_cartao-inter'
import QuemPodeContratar from './sections/quem-pode-contratar/_quem-pode-contratar'
import Alert from './sections/alert/_alert'

import CartaoConsignadoForm from 'src/components/UI/Forms/CartaoConsignado/CartaoConsignadoForm'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const CartaoConsignado = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <AproveiteVantagens />
        <CartilhaCartaoConsignado />
        <CartaoConsignadoVideo />
        <CartaoInter />
        <QuemPodeContratar />
        <Alert />
        <CartaoConsignadoForm />
      </Layout>
    </Wrapper>
  )
}

export default CartaoConsignado
