import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// components
import Icon from 'src/components/UI/MarkdownIcon'

import usePageQuery from './../../pageQuery'

const CartilhaCartaoConsignado = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <section id='cartilha-cartao-de-credito-consignado'>
      <div className='container'>
        <div className='py-5 border-top mt-5'>
          <div className='mb-5'>
            <h2 className='text-center text-gray--800 fs-30 lh-36 fs-md-42 lh-md-46'>Cartilha Cartão de Crédito Consignado</h2>
            <div className='row justify-content-center'>
              <div className='col-12 col-xl-10'>
                <p className='text-gray--600 fs-18 lh-22 text-center'>
                  Mais facilidade para você, aposentado e servidor de órgãos conveniados, fazer compras e saques, à vista ou
                  parcelados. Parte da fatura é descontada diretamente do seu benefício/salário, e é você quem autoriza esse
                  percentual. Sua fatura é mensal, de acordo com a utilização do seu cartão.
                </p>
              </div>
            </div>
          </div>
          <div className='row align-items-center justify-content-between mb-5'>
            <div className='col-12 col-md-7'>
              <div className='d-flex mb-4'>
                <div className='mr-4'>
                  <div className='rounded-circle p-3 border box-shadow'>
                    <Icon directory='v2' icon='pagamentos/card-money' width='30' height='30' />
                  </div>
                </div>
                <div>
                  <h4 className='fs-24 lh-28 fw-600 mb-2 text-gray--800'>Limite</h4>
                  <p className='text-gray--600 fs-18 lh-22'>
                    Seu limite no Cartão de Crédito Consignado fica disponível para saques ou compras à vista ou
                    parceladas.
                    Você também pode sacar em caixas eletrônicos ou pelos canais de atendimento do Inter, com depósito do
                    valor a ser sacado feito diretamente em sua conta.
                  </p>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mr-4'>
                  <div className='rounded-circle p-3 border box-shadow'>
                    <Icon directory='v2' icon='pagamentos/pagamento-recebido' width='30' height='30' />
                  </div>
                </div>
                <div>
                  <h4 className='fs-24 lh-28 fw-600 mb-2 text-gray--800'>Pagamento</h4>
                  <p className='text-gray--600 fs-18 lh-22'>
                    O pagamento da sua fatura mensal ou parte dela é feito por desconto direto no seu benefício/salário, de
                    acordo com a Reserva de Margem Consignável (RMC), que é a limitação percentual na sua renda que pode ser
                    utilizada em um consignado.
                  </p>
                  <p className='text-gray--600 fs-18 lh-22'>Se o valor utilizado nesse período for superior ao RMC, você paga a diferença na sua fatura.</p>
                </div>
              </div>
            </div>
            <div className='d-none d-md-block col-md-4'>
              <Img fluid={data.cartilhaConsignado2.fluid} alt='Cartilha consignado' />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-10 bg-form-gray p-4 p-md-5'>
              <h4 className='fs-24 lh-28 text-gray--800 text-center'>Fique atento!</h4>
              <p className='text-gray--700 fs-18 lg-22 text-center'>Acompanhe sua fatura e verifique o valor descontado pela RMC e o saldo devedor, assim como os descontos da RMC realizados em seu benefício/salário.</p>
              <p className='text-gray--700 fs-18 lg-22 text-center mb-0'>
                <a
                  href='https://static.bancointer.com.br/cartilha-cartao-consignado.pdf'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      element_action: 'click button',
                      element_name: ' Faça o download da nossa cartilha e saiba mais sobre o Cartão de Crédito Consignado.',
                      section_name: 'Cartilha Cartão de Crédito Consignado',
                      redirect_url: 'https://static.bancointer.com.br/cartilha-cartao-consignado.pdf',
                    })
                  }}
                >
                  Faça o download da nossa cartilha
                </a> e saiba mais sobre o Cartão de Crédito Consignado.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CartilhaCartaoConsignado
