import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import bgVideoCartaoConsignado from 'assets/images/temps/bg-video-cartao-consignado.png'

export const CartaoConsignadoVideoSection = styled.section`
h2 {
  margin-bottom: 8px;
}

background-image: url(${bgVideoCartaoConsignado});
background-repeat: no-repeat;
background-size: cover;
background-position: center;
padding: 20px 0;

@media ${device.mobile} {
  padding: 50px 0px;
}

@media ${device.tablet} {
  align-items: center;
  background-position: 65%;
  display: flex;
  height: calc(100vh - 140px);
  transition: ease-out all 700ms;
  padding: 60px 0;
  width: 100%;
  z-index: 1;
}

@media ${device.desktopLG} {
  background-position: center;
  height: 450px;
  min-height: 450px;
  padding: 60px 0px;
}

@media ${device.desktopXXL} {
  height: 550px;
  min-height: 550px;
}
`
