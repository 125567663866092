import React from 'react'
import Img from 'gatsby-image'

// components
import Icon from 'src/components/UI/MarkdownIcon'

// style
import { NegativeMarginColumn } from './style'

import usePageQuery from './../../pageQuery'

const AproveiteVantagens = () => {
  const data = usePageQuery()
  return (
    <section id='vantagens' className='pt-4 pt-md-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-6 offset-lg-6 text-center text-lg-left'>
            <h2 className='fs-28 fs-md-42'>Aproveite as vantagens</h2>
          </div>
          <NegativeMarginColumn className='col-12 col-lg-6 mt-3'>
            <Img className='m-auto' fluid={data.bgCartaoMulher.fluid} alt='Cartão Consignado Banco Inter' />
          </NegativeMarginColumn>
          <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mt-3'>
            <div className='boxed--shadow box-list'>
              <div>
                <Icon width='52' height='40' icon='pagamentos/deposito-boletos' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Desconto em folha</strong><br />O pagamento mínimo da fatura é descontado
                direto na sua folha.
              </p>
            </div>
            <div className='boxed--shadow box-list'>
              <div>
                <Icon width='52' height='40' icon='products/banco/zero-tax' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Sem anuidade</strong><br />Sem cobranças surpresa de taxas.</p>
            </div>
            <div className='boxed--shadow box-list'>
              <div>
                <Icon width='52' height='40' icon='action/credit-analysis' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Sem consulta ao SPC ou SERASA</strong><br />Fique tranquilo, não olhamos a
                situação do seu nome.
              </p>
            </div>
            <div className='boxed--shadow box-list'>
              <div>
                <Icon width='52' height='40' icon='products/credito/credito' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Saque usando o limite do seu cartão</strong><br />Você pode sacar até 90%
                do seu limite.
              </p>
            </div>
            <div className='boxed--shadow box-list'>
              <div>
                <Icon width='52' height='40' icon='pagamentos/card-track' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Cartão Internacional</strong><br />Desbloqueie essa função para fazer
                compras internacionais.
              </p>
            </div>
            <div className='boxed--shadow box-list mb-0'>
              <div>
                <Icon width='52' height='40' icon='products/seguros/taxa-menores' directory='v2' />
              </div>
              <p className='fs-16 lh-20 mb-0'><strong>Menores taxas</strong><br />Taxas de juros muito menores do que as do mercado.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AproveiteVantagens
