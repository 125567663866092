import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`

  .form-section {
    padding-bottom: 70px;
    padding-top: 50px;

    &--gray {
      background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 23.17%, #000000 100%);

      h2 {
        color: ${white};
      }

      .custom-form {
        background: ${white};

        small {
          display: block;
          font-size: 74%;
          margin-top: 3px;
          line-height: 1.4;
        }

        label[for=home_equity_authorization] {
          margin-bottom: 0;
        }

        .inter-forms--send-button-container {
          display: flex;
          justify-content: center;
        }
      }

      .description {
        &__subtitle {
          color: ${white};
          font-size: 21px;
          font-weight: 200;
          line-height: 30px;
          letter-spacing: 0.33px;
          font-weight: bold;
        }

        &__text {
          color: ${white};
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 18px;
          font-weight: normal;
        }
      }   
    }

    &--white {
      background: ${white};

      .custom-form {
        background-color: $gray-100;
        border: 1px solid $gray-500;
        box-shadow: none;

        .success-container {
          background-color: $gray-100 !important;
        }

        button {
          &.loading:disabled {
            background: none;
          }
        }

        .loading:hover {
          background-color: transparent;
          color: transparent;
        }
      }
    }

    &--gray-light {
      background: $gray-100;

      .custom-form {
        background-color: ${white};
        border: 1px solid $gray-500;
        box-shadow: none;

        input, textarea {
          border-color: $gray-500;
        }

        span.inter-forms--select {
          border-color: $gray-500;
        }

        .success-container {
          background-color: ${white} !important;
        }
      
        button {
          &.loading:disabled {
            background: none;
          }
        }

        .loading:hover {
          background-color: transparent;
          color: transparent;
        }

        button {

          &:disabled {
            background: $gray-400 !important;
          
            &:before {
              background: $gray-400 !important;
            }
          }
        }
      }
    }

    &--background {

      .custom-form {
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        border-radius: 8.3px;
        box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
        margin-top: 25px;
        padding-left: 20px;
        padding-right: 20px;

        .inter-forms--checkbox {

          .inter-forms--label {
            font-size: 12px;
          }
        }

        >.success-container {
          display: flex;
          justify-content: center;
        }

        @media (min-width: ${device.desktopLG}) {
          border-radius: 8.3px;
          box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
          margin-top: 0;
          padding-left: 45px;
          padding-right: 45px;
        }

        .form-label-check {
          position: relative;
          padding-left: 37px;
          margin-top: -30px;
          display: block;
          font-weight: 700;

          &:before {
            border-color: $gray-base;
          }

          @media (min-width: ${device.tablet}) {
            padding-top: 5px;
            padding-left: 34px;
          }

          a {
            &:hover {
              color: $gray-600;
            }
          }
        }
        
        button {

          &:disabled {
            background: ${white};
          
            &:before {
              background: ${white};
            }
          }
        }
        
        &#consignado_privado {

          .pb-3.col-12 {

            &:last-child {
              padding-bottom: 30px !important;
            }   
          } 

          .form-label-check {
            padding-top: 3px;

            &:before {
              border-color: ${white};
            }
          }

          .form-input-check {

            &:checked~label {
            
              &::after {
                border-color: ${white};
              }
            }
          }       
        }  
        
        .inter-forms--clickable > input:checked + label::before {
          background: linear-gradient(7.83deg, $orange-dark 0%, $orange-base 100%);
          border: 1px solid $orange-dark;
        }

        .inter-forms--clickable > input:checked + label::after {
          border-color: ${white};
          border-bottom: solid 2px ${white};
          border-left: solid 2px ${white};
          left: 5px;
          height: 6px;
          width: 10px;
        }
      }
    }

    .btn--block {
      border-radius: 8px;
      max-width: 300px;

      @media (min-width: map-get($inter-grid-breakpoints, md)) {
        margin: 20px auto 0;
        min-width: auto;
      }
    
      button {
        &.loading:disabled {
          background: none;
        }
      }

      &.loading:hover {
        background-color: transparent !important;
        background: none !important;
        color: transparent !important;
      }
    }

    .boxed {
      background-image: linear-gradient(#FF500F, #FF500F), linear-gradient(#FF500F, #FF500F), linear-gradient(#FF500F, #FF500F), 
      linear-gradient(#FF500F, #FF500F), linear-gradient(#FF8700, #FF8700), linear-gradient(#FF8700, #FF8700), 
      linear-gradient(#FF8700, #FF8700), linear-gradient(#FF8700, #FF8700) !important;

      &--lg {
        background-image: linear-gradient(#FF500F, #FF500F), linear-gradient(#FF500F, #FF500F), linear-gradient(#FF500F, #FF500F), 
        linear-gradient(#FF500F, #FF500F), linear-gradient(#FF8700, #FF8700), linear-gradient(#FF8700, #FF8700), 
        linear-gradient(#FF8700, #FF8700), linear-gradient(#FF8700, #FF8700) !important;
      }
    }

    .pb-3.col-12 {

      &:last-child {
        padding-bottom: 30px !important;
      }   
    } 

    .pb-3.col-12.col-md-6 {

      &:last-child {
        padding-bottom: 30px !important;
      }   
    } 
  }

  .form-container {

    .form-label-check {

      @media (min-width: ${device.tablet}) {
        padding-top: 5px;
      }
    }

    button {

      &:disabled {
        color: $gray-400 !important;
      }
    }
  }

  .simulator {

    .custom-form {

      .pb-3.col-12.col-md-6 {

        &:last-child {
          padding-bottom: 0 !important;
        }

        &:nth-child(8) {
          @media (min-width: ${device.tablet}) {
            padding-bottom: 0 !important;
          }
        }
      } 

      .form-label-check {
        @media (min-width: ${device.tablet}) {
          padding-top: 4px;
        }
      }

      button {

        &:disabled {
          color: ${white};
        }
      }
    }
  }
`

export const BoxedTitle = styled.div`

`
export const FormStyle = styled.form`

`
