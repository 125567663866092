import React from 'react'

import SuccessSVG from 'inter-frontend-svgs/lib/layout/success'
import AppStore from 'inter-frontend-svgs/lib/stores/app-store-mono-pt'
import PlayStore from 'inter-frontend-svgs/lib/stores/play-store-mono-pt'

type submittedMessagesProps = {
  message: {
    success_text: string;
    success: string;
    previous_page: string;
  };
  onCloseClick: () => void;
}

const createMarkup = (msg: string) => ({ __html: msg })

function DefaultSubmittedMessage ({ message, onCloseClick }: submittedMessagesProps) {
  return (
    <div className='success-container bg-white text-center'>
      <div className='h-100 d-flex align-items-center'>
        <div className='box-success'>
          <SuccessSVG width='230' height='230' color='orange--base' />
          <p className='fs-22 fw-300'>{message.success}</p>
          <div>
            <p dangerouslySetInnerHTML={createMarkup(message.success_text)} />
            <div className='row pb-3 app-display-none'>
              <div className='col-6 text-right'>
                <a href='https://itunes.apple.com/br/app/banco-inter/id839711154' title='Baixe na App Store'>
                  <AppStore width='114' height='39' color='gray' />
                </a>
              </div>
              <div className='col-6 text-left'>
                <a href='https://play.google.com/store/apps/details?id=br.com.intermedium' title='Baixe no Google Play'>
                  <PlayStore width='118' height='39' color='gray' />
                </a>
              </div>
            </div>
            <a href='#' onClick={onCloseClick} className='fs-13 btn-link'>{message.previous_page}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

DefaultSubmittedMessage.defaultProps = {
  message: {},
  onCloseClick: () => Object,
}

export default DefaultSubmittedMessage
