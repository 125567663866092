import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      cartaoDesktopBancoInter: imageSharp(fluid: { originalName: { regex: "/cartao-desktop-banco-inter/" }}) {
        fluid(maxWidth: 553, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bgCartaoMulher: imageSharp(fluid: { originalName: { regex: "/bg-mulher-cartao/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartilhaConsignado2: imageSharp(fluid: { originalName: { regex: "/cartilha-consignado-2/" }}) {
        fluid(maxWidth: 337, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
