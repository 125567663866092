import React from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// components
import BreadCrumb from 'src/components/Breadcrumb'
import ScrollTo from 'src/components/ScrollTo'

// style
import { ContentHeader } from './style'

import usePageQuery from './../../pageQuery'

const Header = () => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <ContentHeader>
      <div className='container'>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-12 offset-lg-1 mb-3 mb-md-5 d-block d-lg-none'>
            <BreadCrumb
              sectionName='Cartão de Crédito Consignado'
              type='pra você'
              text='cartão de crédito consignado'
              link='/pra-voce/emprestimos/cartao-consignado/'
            />
          </div>
          <div className='col-12 col-md-8 col-lg-6 d-flex justify-content-center mb-3 mt-lg-4 order-lg-last offset-lg-1'>
            <Img fluid={data.cartaoDesktopBancoInter.fluid} loading='eager' alt='Cartão Consignado Banco Inter' />
          </div>
          <div className='col-12 col-lg-5'>
            <div className='col-12 mb-3 px-0 d-none d-lg-block'>
              <BreadCrumb
                sectionName='Cartão de Crédito Consignado'
                type='pra você'
                text='cartão de crédito consignado'
                link='/pra-voce/emprestimos/cartao-consignado/'
              />
            </div>
            <h1 className='fs-32 fs-md-50 lh-lg-55 fw-600 text-white'>Cartão de Crédito <br /> Consignado</h1>
            <p className='my-4 lh-25 text-white'>
              Aproveite um cartão de crédito com desconto direto na folha de pagamento!
              Com ele, você faz compras e saques igual nos cartões de créditos tradicionais.
            </p>
            <p className='text-white'>Consulte os convênios.</p>
            <div>
              <ScrollTo
                to='#bottom-form'
                section='dobra_01'
                sectionName='Cartão de Crédito Consignado'
                elementName='Solicitar meu Cartão'
                styles='btn btn--lg mb-4 btn--lg btn--white rounded-2 mt-2 mb-4 mt-md-4'
              >
                Solicitar meu Cartão
              </ScrollTo>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default Header
