import styled from 'styled-components'
import { breakpoints, device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'

export const ContentHeader = styled.section`
  align-items: center;
  display: flex;
  min-height: 640px;
  position: relative;

  &::before {
    content: '';
    background-image: linear-gradient(208.07deg, rgb(255, 135, 0) 0%, rgb(255, 80, 15) 100%);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  @media ${device.tablet} {
    min-height: 900px;
  }

  @media ${device.desktopLG} {
    min-height: calc(100vh - 73px);
  }

  .call-to-action {
    background: none !important;
    box-shadow: none;
    margin-bottom: 0;
    text-align: left !important;

    > div {
      padding: 0;
    }

    @media (max-width: ${breakpoints.md}) {
      &.sidebar-box {
        padding-top: 0;
      }
    }

    a {
      background: ${white};
      border: 0 !important;
      color: ${orange.base};

      &.btn {
        padding-bottom: 0;
        padding-top: 0;
        margin-bottom: 0;
      }

      &.btn::before {
        background-color: ${orange.base};
      }

      &:hover {
        border: 0 !important;
        color: ${white};
      }
    }
  }
`
