import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Icon from 'src/components/UI/MarkdownIcon'

const Alert = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <section className='bg-white pt-4'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <div className='pb-5 d-flex align-items-center'>
              <div className='ml-1'>
                <Icon width='30' height='30' icon='action/stats/warning' directory='v2' />
              </div>
              <div className='mx-2 mx-md-3'>
                <p className='mb-0'>
                  Prezamos pelo seu bem-estar! Se julgar que o contato do Banco Inter para promoções e serviços por telefone está invasivo, acesse
                  <a
                    href='https://www.naomeperturbe.com.br'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_07',
                        element_action: 'click button',
                        element_name: 'www.naomeperturbe.com.br para bloquear as ligações.',
                        section_name: 'Prezamos pelo seu bem-estar! Se julgar que o contato do Banco Inter para promoções e serviços por telefone está invasivo, acesse',
                        redirect_url: 'https://www.naomeperturbe.com.br',
                      })
                    }}

                  >
                    {' '}www.naomeperturbe.com.br
                  </a> para bloquear as ligações.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Alert
