import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

// components
import Video from 'src/components/Video'
import YoutubeVideo from 'components/YoutubeVideo'

// style
import { CartaoConsignadoVideoSection } from './style'

const CartaoConsignadoVideo = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  return (
    <CartaoConsignadoVideoSection id='vantagens-do-seu-cartao'>
      <Video
        title='Conheça mais vantagens do seu Cartão de Crédito Consignado'
        subtitle=''
        onClick={() => {
          sendDatalayerEvent({
            section: 'dobra_04',
            element_action: 'click button',
            element_name: '',
            section_name: 'Conheça mais vantagens do seu Cartão de Crédito Consignado',
          })
        }}
      >
        <YoutubeVideo width='100%' height='100%' src='https://www.youtube.com/embed/4nDsCydMdxY' />
      </Video>
    </CartaoConsignadoVideoSection>
  )
}

export default CartaoConsignadoVideo
