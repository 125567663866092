import React from 'react'

import SummaryDetails from 'src/components/UI/SummaryDetails'
import FAQ from 'src/components/Faq/index'

import pageContext from './../../pageContext.json'

const QuemPodeContratar = () => {
  return (
    <section id='quem-pode-contratar'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <div className='col-12 mt-5 mb-md-3 px-lg-3'>
            <h2 className='fs-28 fs-md-42 mb-3 text-md-center'>Quem pode contratar?</h2>
            <div className='d-flex justify-content-center px-0'>
              <div className='col-12 col-md-7 col-lg-5 px-0'>
                <p className='fs-16 text-md-center'>Confira as empresas associadas e convênios que podem pedir o Cartão de
                  Crédito Consignado.
                </p>
              </div>
            </div>
            <div className='row mt-3 pb-5'>
              <div className='col-lg-6'>
                <SummaryDetails summary='Aposentados e pensionistas' id='Aposentados e pensionistas' found={false}>
                  <ul className='summary-content'>
                    <li>INSS</li>
                  </ul>
                </SummaryDetails>
                <SummaryDetails summary='Servidores Municipais' id='Servidores Municipais' found={false}>
                  <ul className='summary-content'>
                    <li>Prefeitura de Belo Horizonte</li>
                  </ul>
                </SummaryDetails>
                <SummaryDetails summary='Servidores Públicos Federais' id='Servidores Públicos Federais' found={false}>
                  <ul className='summary-content'>
                    <li>SIAPE</li>
                  </ul>
                </SummaryDetails>
              </div>
              <div className='col-lg-6'>
                <SummaryDetails summary='Servidores dos Tribunais de Justiça' id='Servidores dos Tribunais de Justiça' found={false}>
                  <ul className='summary-content'>
                    <li>TJMG</li>
                  </ul>
                </SummaryDetails>
                <SummaryDetails summary='Servidores Públicos Estaduais' id='Servidores Públicos Estaduais' found={false}>
                  <ul className='summary-content'>
                    <li>Governo do Estado de Minas Gerais</li>
                    <li>Governo do Estado de São Paulo</li>
                    <li>Governo do Estado do Rio de Janeiro</li>
                  </ul>
                </SummaryDetails>
              </div>
            </div>
            <h3 className='fs-28 fs-md-42 text-md-center mt-2'>Perguntas frequentes</h3>
            <div className='d-flex justify-content-center'>
              <div className='col-12 col-md-8 col-xl-6 px-0'>
                <p className='fs-16 text-md-center'>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas perguntas
                  ou entre em contato com a gente.
                </p>
              </div>
            </div>
            <section className='row mt-3 px-lg-3'>
              <FAQ answerData={pageContext.structuredData.faq} />
            </section>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QuemPodeContratar
