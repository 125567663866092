import React, { useRef, memo, ReactNode } from 'react'
import { Modal } from 'inter-site-components'
import ModalPlayIcon from 'inter-frontend-svgs/lib/site/play'
import './Video.scss'

type VideoProps = {
  title: string;
  subtitle: string;
  children: ReactNode;
  onClick?: Function;
}

type ModalProps = {
  close: () => void;
  open: () => void;

}

function Video ({ title, subtitle, children, onClick }: VideoProps) {
  const modalRef = useRef<ModalProps>(null)

  function handleClose () {
    modalRef.current && modalRef.current.close()
  }

  function handleOpen () {
    modalRef.current && modalRef.current.open()
    if (onClick) {
      onClick()
    }
  }

  return (
    <div className='container'>
      <div className='row text-center justify-content-center'>
        <div className='col-12 col-lg-8 mt-3 mt-md-0'>
          <h2 className='text-white fs-22 fs-md-42 mt-lg-2'>{title}</h2>
          <h3 className='text-white fs-20 fs-md-32 mt-lg-2 mb-0'>{subtitle}</h3>
        </div>
        <div className='col-12 pt-2 pt-md-5 pt-lg-0'>
          <div className='video-show'>
            <div className='play-action' onClick={handleOpen}>
              <ModalPlayIcon color='white' />
            </div>
            <Modal ref={modalRef}>
              <div className='modal'>
                <div className='backdrop' onClick={handleClose} />
                <div className='content'>
                  <div className='close-button' onClick={handleClose}>X</div>
                  {children}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Video)
