import React, { useState, MouseEvent, ChangeEvent } from 'react'
import { useForm, UseFormMethods } from 'react-hook-form'
import { format } from 'date-fns'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { getParameterByName, getCookie, sendCDPFormData } from 'src/shared/helpers'
import AcceptTerms from 'src/components/AcceptTerms'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultSubmittedMessage from 'src/components/UI/Forms/Submit/Messages/DefaultSubmittedMessage'
import { Container, BoxedTitle, FormStyle } from './style'
import convenioJson from './assets/data/convenio.json'
import organJson from './assets/data/organ.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { apiForm } from 'src/config/api/axiosRequest'

interface IFormValues {
  firstName: string;
  email: string;
  mobile: string;
  dateOfBirth: string;
  description: string;
  convenio: string;
  cpf: string;
  enrollmentCartao: string;
  organ: string;
  promotionReceivables: boolean;
  acceptTerms: boolean;
}
interface IConvenioSelect {
  value: string;
  label: string;
}

interface IOrganData {
  [param: string]: IConvenioSelect[];
}

const Form = () => {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ email, setEmail ] = useState('')
  const [ accept, setAccept ] = useState(false)
  const [ acceptPromotion, setAcceptPromotion ] = useState(false)
  const [ convenioArray, setConvenioArray ] = useState<string>('inss-cartao-consignado')
  const gclid = getParameterByName('gclid', undefined) || ''
  const gaid = getCookie('_ga')
  const [ sendDatalayerEvent ] = useDataLayer()

  const organData: IOrganData = organJson

  const sucessMsg = {
    success_text: 'Vamos retornar com sua proposta de <strong>crédito consignado</strong> por e-mail ou telefone. Fique de olho!',
    success: 'Recebemos o seu contato.',
    previous_page: 'VOLTAR',
  }
  const sendForm = async (data: IFormValues) => {
    setError(false)
    setLoading(true)

    const { mobile, convenio, cpf, dateOfBirth, email, enrollmentCartao, firstName, organ }: IFormValues = data
    const formData = {
      acceptTerms: true,
      promotionReceivables: acceptPromotion ? true : false,
      mobile: mobile.replace(/[^\w\s]/gi, '').replace(' ', ''),
      lastName: firstName.trim().split(/(?<=^\S+)\s/)[1],
      gclid: gclid,
      gaClientId: gaid,
      firstName: firstName.trim().split(' ')[0],
      email: email.trim(),
      description: `Convênio: ${convenio} Orgão: ${organ}`,
      dateOfBirth: format(new Date(dateOfBirth), 'yyyy-MM-dd'),
      cpf: cpf.replace(/[^\w\s]/gi, '').replace(' ', ''),
      enrollmentCartao: enrollmentCartao,
      }

    try {
      await apiForm.post(`${URLS.CONTACT_FORM_POST_V5}/lead-cartao-consigado-site`, [ formData ])
      setSent(true)
      sendDatalayerEvent({
        section: 'dobra_08',
        element_action: 'submit',
        element_name: 'Enviar',
        section_name: 'Solicite seu cartão',
      })
      sendCDPFormData({ formName: 'Formulario cartão consignado', cpf: formData.cpf, email: formData.email })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setAccept(false)
    setAcceptPromotion(false)
    setConvenioArray('inss-cartao-consignado')
    setEmail('')
  }

  function handlePromotionCheck (evt: ChangeEvent<HTMLInputElement>) {
    const inputValuePromotion = evt.currentTarget.checked
    setAcceptPromotion(inputValuePromotion)
  }

  if (sent) {
    return (
      <Container>
        <section className='form-section form-section--gray-light' id='bottom-form'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-12 col-lg-4 d-flex align-items-center'>
                <BoxedTitle className='boxed boxed--lg boxed--white text-left boxed--product-list boxed--base-height'>
                  <h3>Solicite seu cartão</h3>
                </BoxedTitle>
              </div>
              <div className='col-sm-12 col-lg-8 form-section--background'>
                <div className='custom-form'>
                  <DefaultSubmittedMessage message={sucessMsg} onCloseClick={() => handleReturn} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Container>
    )
  }

  return (
    <Container>
      <section className='form-section form-section--gray-light' id='bottom-form'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 col-lg-4 d-flex align-items-center'>
              <BoxedTitle className='boxed boxed--lg boxed--white text-left boxed--product-list boxed--base-height'>
                <h3>Solicite seu cartão</h3>
              </BoxedTitle>
            </div>
            <div className='col-sm-12 col-lg-8 form-section--background'>
              <FormStyle
                onSubmit={handleSubmit(sendForm)}
                className='custom-form'
              >
                <div className='row'>
                  <div className='col-12 pb-3'>
                    <label htmlFor='firstName' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Nome</label>
                    <input
                      className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                      ref={register({
                        required: 'Nome obrigatório',
                        validate: {
                          isName: (value: string) => Validations.name(value) || 'Insira o nome completo',
                        },
                      })}
                      name='firstName'
                      id='firstName'
                      type='text'
                      maxLength={80}
                      placeholder='Informe o nome completo'
                    />
                    { errors.firstName && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.firstName.message}</p> }
                  </div>
                  <div className='col-12'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='email' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>E-Mail</label>
                      <input
                        className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                        ref={register({
                          required: 'Informe um e-mail válido',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'E-mail inválido',
                          },
                        })}
                        name='email'
                        id='email'
                        value={email}
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
                        maxLength={80}
                        type='text'
                        placeholder='Informe seu e-mail'
                      />
                      { errors.email && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.email.message}</p> }
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='mobile' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Telefone</label>
                      <input
                        className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                        ref={register({
                          required: 'Telefone obrigatório',
                          pattern: {
                            value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                            message: 'Telefone inválido',
                          },
                        })}
                        name='mobile'
                        id='mobile'
                        type='tel'
                        placeholder='Informe o telefone'
                        maxLength={15}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('mobile', Masks.MaskPHONE(event.currentTarget.value))}
                      />
                      { errors.mobile && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.mobile.message}</p> }
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='dateOfBirth' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Data de Nascimento</label>
                      <input
                        className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                        ref={register({
                          required: 'Digite uma data válida',
                        })}
                        name='dateOfBirth'
                        id='dateOfBirth'
                        type='date'
                        maxLength={10}
                      />
                      {errors.dateOfBirth && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.dateOfBirth.message}</p>}
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='convenio' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Convênio</label>
                      <span className='select mb-0 inter-forms--select inter-forms--box '>
                        <select
                          ref={register}
                          name='convenio'
                          id='convenio'
                          onChange={(evt: ChangeEvent<HTMLSelectElement>) => setConvenioArray(evt.target.value)}
                        >
                          {convenioJson.map((data: IConvenioSelect) => (
                            <option key={data.value} value={data.value}>{data.label}</option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='organ' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Órgão</label>
                      <span className='select mb-0 inter-forms--select inter-forms--box '>
                        <select
                          ref={register}
                          name='organ'
                          id='organ'
                        >
                          {organData[convenioArray].map((data: IConvenioSelect) => (
                            <option key={data.value} value={data.value}>{data.label}</option>
                          ))}
                        </select>
                      </span>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='enrollmentCartao' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>Nº benefício ou matrícula</label>
                      <input
                        className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                        ref={register({
                          required: 'Digite o Nº benefício ou matrícula',
                        })}
                        name='enrollmentCartao'
                        id='enrollmentCartao'
                        type='number'
                        placeholder='Digite o Nº benefício ou matrícula'
                        maxLength={30}
                      />
                      {errors.enrollmentCartao && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.enrollmentCartao.message}</p>}
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='col-12 pb-3 d-flex flex-column mt-2 px-0'>
                      <label htmlFor='cpf' className='fs-12 lh-15 fw-700 text-gray--base inter-forms--label'>CPF</label>
                      <input
                        className='inter-forms--input inter-forms--box inter-forms--box--autocomplete'
                        ref={register({
                          required: 'CPF obrigatório',
                          validate: {
                            isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                          },
                        })}
                        name='cpf'
                        id='cpf'
                        type='tel'
                        placeholder='Digite seu CPF'
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                      />
                      { errors.cpf && <p className='fs-12 mt-1 mb-0 text-red--base fw-600'>{errors.cpf.message}</p> }
                    </div>
                  </div>
                  <div className='col-12 pb-4'>
                    <div className='inter-forms--clickable inter-forms--checkbox'>
                      <input
                        name='promotionReceivables'
                        id='promotionReceivables'
                        type='checkbox'
                        value={`${acceptPromotion}`}
                        className='form-input-check'
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => handlePromotionCheck(evt)}
                      />
                      <label className='inter-forms--label' htmlFor='promotionReceivables'>
                        Desejo receber novidades por e-mail e SMS sobre o Empréstimo Consignado Banco Inter.
                      </label>
                    </div>
                  </div>
                  <div className='pb-3 col-12'>
                    <AcceptTerms accept={accept} setAccept={setAccept} name='acceptTerms' />
                  </div>
                </div>
                <div>
                  <button
                    type='submit' className={`btn my-3 btn--orange btn--block ${loading ? 'loading' : ''}`}
                    title='Enviar' disabled={!accept || loading}
                  >
                    {loading ? 'ENVIANDO...' : 'Enviar'}
                  </button>
                </div>
                {error && (
                  <div className='d-flex align-items-center'>
                    <OrangeIcon size='SM' color='#FF2A44' icon='canceled' />
                    <span className='pl-2'>Ops! Ocorreu um erro ao enviar o contato. Tente novamente!</span>
                  </div>)}
              </FormStyle>
            </div>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default Form
