import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'

const CartaoInter = () => {
  return (
    <section id='cartao-inter' className='bg-gray--100'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mt-5 mb-3 mb-md-5'>
            <h2 className='fs-28 fs-md-42 mb-4 mb-md-5'>O que seu cartão pode fazer?</h2>
            <div className='row'>
              <div className='col-12 col-lg-6 px-2 pr-lg-5'>
                <ul className='list-unstyled'>
                  <li className='d-flex'>
                    <div className='icon--grid'>
                      <Icon width='40' height='40' icon='check' directory='layout' />
                    </div>
                    <div className='mt-2 mt-md-1'>
                      <h4 className='fs-16 fs-md-24 mb-2'>Compras no crédito</h4>
                      <p className='fs-16 py-0 my-0'>Faça compras na função crédito à vista ou parcelado, nacionais e
                        internacionais em lojas físicas ou online.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-lg-6 px-2 pr-lg-5'>
                <ul className='list-unstyled'>
                  <li className='d-flex'>
                    <div className='icon--grid'>
                      <Icon width='40' height='40' icon='check' directory='layout' />
                    </div>
                    <div className='mt-2 mt-md-1'>
                      <h4 className='fs-16 fs-md-24 mb-2'>Saque à vista ou parcelado</h4>
                      <p className='fs-16'>
                        Através do tele saque, você solicita o valor na Central de Atendimento do Banco Inter. A
                        transferência vai para a sua conta corrente e o saque é cobrado na fatura do cartão.
                      </p>
                      <p className='fs-16 mb-0'>Você pode sacar um valor à vista ou parcelado, em até 60 vezes.</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='col-12 col-lg-6 px-2'>
                <ul className='list-unstyled'>
                  <li className='d-flex mt-2 mt-lg-0'>
                    <div className='icon--grid'>
                      <Icon width='40' height='40' icon='check' directory='layout' />
                    </div>
                    <div className='mt-2 mt-md-1'>
                      <h4 className='fs-16 fs-md-24 mb-2'>Saque na Rede Banco24Horas</h4>
                      <p className='fs-16'>Você pode fazer saques à vista nos caixas da Rede Banco24Horas, e o valor será
                        cobrado na sua fatura com juros baixinhos.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CartaoInter
